@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  /* margin-top: 50px; */
  height: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icon{
  top: calc(25%);
}

* {
  cursor:  auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #afb2bf;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: #000814;
}

*::-webkit-scrollbar-thumb {
  background-color: #afb2bf;
  border-radius: 10px;
  visibility: hidden;
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/* Hide default styling of details/summary */
details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

/* https://10015.io/tools/css-loader-generator */
.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid #f1f2ff;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
    spinner-oaa3wk 1.6s infinite linear;
}

.codeblock1 {
  width: 372.95px;
  height: 257.05px;
  border-radius: 100%;
  left: calc(50% - 372.95px / 2 - 76.53px);
  top: calc(50% - 257.05px / 2 - 47.47px);
  background: linear-gradient(
    123.77deg,
    #8a2be2 -6.46%,
    #ffa500 59.04%,
    #f8f8ff 124.53%
  );
  opacity: 0.2;
  filter: blur(34px);
  transform: matrix(1, 0, -0.03, 1, 0, 0);
}
.codeblock2 {
  position: absolute;
  width: 372.95px;
  height: 257.05px;
  left: calc(50% - 372.95px / 2 - 76.53px);
  top: calc(50% - 257.05px / 2 - 47.47px);
  border-radius: 100%;
  background: linear-gradient(
    118.19deg,
    #1fa2ff -3.62%,
    #12d8fa 50.44%,
    #a6ffcb 104.51%
  );
  opacity: 0.2;
  filter: blur(34px);
  transform: matrix(1, 0, -0.03, 1, 0, 0);
}
.code-border {
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to right bottom, #ffffff38, #ffffff00);
  background: linear-gradient(
    111.93deg,
    rgba(14, 26, 45, 0.24) -1.4%,
    rgba(17, 30, 50, 0.38) 104.96%
  );
  /* backdrop-filter: blur(26px); */
}
.homepage_bg {
  background: url("../src/assets/Images/bghome.svg");
  /* background-size: contain; */
}

@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

/* For conditional coloring of icons in dashboard sidebar */
.active-icon {
  fill: #ffd60a !important;
  color: #ffd60a !important;
}

.inactive-icon {
  fill: #838894;
  color: #838894;
}

/* Buttons */
.yellowButton {
  @apply cursor-pointer rounded-md bg-yellow-50 px-[20px] py-[8px] font-semibold text-richblack-900;
}

/* Swiper CSS Starts */
/* .swiper {
  max-width: 1200px;
  width: 91.66%;
  height: 185px;
  margin: 50px 0px;
} */

/* .swiper-slide {
  width: 100%;
  height: 100%;
} */
/* .swiper-slide {
  @apply bg-richblack-800 text-richblack-25 p-3 text-[14px];
} */
/* Swiper CSS Ends */

.blackButton {
  @apply cursor-pointer rounded-md bg-richblack-800 px-[20px] py-[8px] font-semibold text-richblack-5;
}
.lable-style {
  @apply text-[14px] text-richblack-5;
}
.form-style {
  @apply rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-black shadow-[0_1px_0_0] shadow-white/50 placeholder:text-black focus:outline-none;
}
.section_heading {
  @apply text-2xl font-bold text-richblack-5 lg:text-4xl;
}
.navbarContainer {	
  @apply top-0 left-0 right-0 z-50 bg-richblack-800; 
}

/* BackToTop.css */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color: #FFD60A;
  color: #000814;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
}

.back-to-top:hover {
  background-color: #FFE566;  /* Lighter yellow on hover */
}

.back-to-top:focus {
  outline: none;
}

.back-to-top:active {
  transform: translateY(2px);
}

.back-to-top svg {
  font-size: 24px;
}


@keyframes fillAnimation {
  0% {
      box-shadow: 0 0 0 0 currentColor;
  }
  100% {
      box-shadow: 0 0 0 20px transparent;
  }
}

.social-icon {
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  color: currentColor;
  position: relative;
  overflow: visible;
}

.social-icon svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.social-icon:hover {
  animation: fillAnimation 0.5s ease-out;
}

.social-icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  transition: all 0.3s;
  z-index: -1;
}

.social-icon:hover::after {
  background-color: currentColor;
  opacity: 0.1;
  transform: scale(1.2);
}

/* Color classes remain the same */
.facebook { color: #3b5998; }
.google { color: #db4437; }
.twitter { color: #1da1f2; }
.youtube { color: #ff0000; }
.linkedin { color: #0077b5; }
.instagram { color: #e4405f; }

@media (max-width:375px) {

/* Footer styles */
.footer-content {
  @apply flex flex-col lg:flex-row gap-8 items-start justify-between w-11/12 max-w-maxContent text-richblack-400 leading-6 mx-auto relative py-14;
}

.footer-section {
  @apply w-full border-b lg:border-b-0 pb-5 border-richblack-700;
}

.footer-left {
  @apply flex flex-col lg:flex-row justify-between gap-3;
}

.footer-company {
  @apply flex flex-col gap-3 lg:w-[30%] mb-7;
}

.footer-logo {
  @apply object-contain w-40;
}

.footer-heading {
  @apply text-white font-semibold text-[16px] mb-2;
}

.footer-links {
  @apply flex flex-col gap-2;
}

.footer-link {
  @apply text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200;
}

.footer-social {
  @apply flex gap-3 text-lg;
}

.social-icon {
  @apply transition duration-300 hover:opacity-75 hover:bg-white;
}

.footer-email {
  @apply mt-3;
}

.email-input {
  @apply flex items-center mt-2;
  width: 214px;
  height: 50px;
}

.email-input input {
  @apply p-2 rounded-l-md w-full text-sm border-2 border-yellow-50 focus:outline-none;
}

.email-input a {
  @apply bg-yellow-50 p-2 rounded-r-md;
}

.send-icon {
  @apply text-black text-xl;
}

.footer-resources, .footer-plans {
  @apply w-1/2 lg:w-[30%] mb-7;
}

.footer-right {
  @apply flex flex-wrap justify-between lg:w-[40%];
}

.footer-column {
  @apply w-full lg:w-1/2 mb-7;
}

.footer-bottom {
  @apply w-11/12 max-w-maxContent mx-auto pb-14 text-sm;
}

.bottom-links {
  @apply flex flex-wrap justify-center lg:justify-start gap-3 mb-3 lg:mb-0 text-white;
}

.bottom-link {
  @apply cursor-pointer hover:text-richblack-50 transition-all duration-200;
}

.bottom-link:not(:last-child) {
  @apply border-r border-richblack-700 pr-3;
}

.copyright {
  @apply text-center lg:text-right text-white;
}

/* Responsive adjustments */

@media (max-width:600px) {

  .text-white{
      width:23rem;
    }
  }

  .heading{
    margin-left: -2rem;
  }

}


  @media (max-width: 768px) {
    .footer-left, .footer-right {
      @apply flex-col;
    }
    .footer-resources, .footer-plans, .footer-column {
      @apply w-full;
    }  
    .bottom-links, .copyright {
      @apply text-center;
    }
  }


@media (max-width: 325px) {
  .text-white {
    width: 18rem;
  }
}

@media (max-width: 600px) and (min-width: 325px){
  .text-white {
    width: 23rem;
  }
}

@media (max-width: 768px) {
  .footer-left, .footer-right {
    @apply flex-col;
  }

  .footer-resources, .footer-plans, .footer-column {
    @apply w-full;
  }  

  .bottom-links, .copyright {
    @apply text-center;
  }
}

.container {
  display: flex;
  flex-direction: column; /* Ensure items are stacked vertically */
  align-items: flex-start; /* Align items to the start (left) */
}

.text-richblack-50 {
  margin-bottom: 0.5rem; /* Keep the margin as specified */
  text-align: left; /* Ensure text alignment to the left */
}

@media (max-width: 340px) {
  .form{
    width: 16rem;
  }
  #phonenumber{
    margin-left: -13px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
